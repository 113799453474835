import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { AccountManagementState } from "../states";
import { GenericResponse, MarketPlace } from "../models";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class isActiveAmazonAccessGuard implements CanActivate {
  @Select(AccountManagementState.getMarketPlacesResponse)
  marketPlaces$: Observable<GenericResponse<MarketPlace[]>>;

  constructor(private router: Router, private http: HttpClient) {}

  findTargetIds(data, id) {
    if (!data || !id) return null;
    return data.find((marketplace: any) => {
      if (marketplace.marketplaceId == id) return true;
    });
  }

  fetchMarketplace(marketplaceId: string): Promise<any> {
    return this.http
      .get(`https://api.dropyonline.com/api/v1/account/marketplaces/${marketplaceId}`)
      .toPromise();
  }

  canActivate(
    next: ActivatedRouteSnapshot | string,
    state: RouterStateSnapshot | string
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.marketPlaces$.subscribe(async (d) => {
        if (!d || d?.Result[0]) {
          const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
          if (marketplaceId) {
            let selectedMarketPlace = this.findTargetIds(
              d?.Result,
              localStorage.getItem("selectedMarketPlaceId")
            );
            if (!selectedMarketPlace) {
              try {
                selectedMarketPlace = await this.fetchMarketplace(marketplaceId);
              } catch (error) {
                console.error("Error fetching marketplace:", error);
                resolve(false);
                return;
              }
            }
            if (
              !selectedMarketPlace ||
              selectedMarketPlace.isAmazonAccessFails == true ||
              selectedMarketPlace.isAmazonConnected == false
            ) {
              resolve(false);
            } else {
              resolve(true);
            }
          }         
        }
      });
      
    });
  }
}
